<template>
    <div class="page-modal">
        <!-- 弹出框 -->
        <el-dialog v-model="showModal" width="80%" center>
            <el-skeleton v-if="skeleton" :rows="9" :animated='true' :loading='true' />
            <!-- 订单详情内容 -->
            <div v-else>
                <div v-if="currentOrder.status != 3 && currentOrder.status != 5 && currentOrder.status != 6"
                    class="statuscheck">
                    <el-button type="primary" :icon="Refresh"
                        @click="syncStatus(currentOrder.id, currentOrder.created_at)">
                        手动检测
                    </el-button>
                </div>
                <el-descriptions :column="2" border>
                    <el-descriptions-item label="交易流水号">{{ currentOrder.trade_no }}</el-descriptions-item>
                    <el-descriptions-item label="渠道交易号">{{ currentOrder.channel_trade_no }}</el-descriptions-item>
                    <el-descriptions-item label="商户订单号">{{ currentOrder.out_trade_no }}</el-descriptions-item>
                    <el-descriptions-item label="商户名称">{{ currentOrder.merchant_name }}</el-descriptions-item>
                    <el-descriptions-item label="商户编号">
                        {{ currentOrder.merchant_no }}
                    </el-descriptions-item>
                    <el-descriptions-item label="门店名称">{{ currentOrder.store_name }}</el-descriptions-item>
                    <el-descriptions-item label="代理商名称">{{ currentOrder.agent_name }}</el-descriptions-item>
                    <el-descriptions-item label="支付设备">{{ currentOrder.serial_number }}</el-descriptions-item>
                    <el-descriptions-item label="交易费率">{{ currentOrder.rate }}</el-descriptions-item>
                    <el-descriptions-item label="手续费">{{ currentOrder.fee_amount }}</el-descriptions-item>
                    <el-descriptions-item label="订单金额(元)">{{ currentOrder.total_amount }}</el-descriptions-item>
                    <el-descriptions-item label="支付金额(元)">{{ currentOrder.pay_amount }}</el-descriptions-item>
                    <el-descriptions-item label="优惠金额(元)">{{ currentOrder.discount_amount }}</el-descriptions-item>
                    <el-descriptions-item label="商户实收金额(元)">{{ currentOrder.receipt_amount }}</el-descriptions-item>
                    <el-descriptions-item label="退款金额(元)">{{ currentOrder.refund_amount }}</el-descriptions-item>
                    <el-descriptions-item label="支付方式">{{ currentOrder.payment_method }}</el-descriptions-item>
                    <el-descriptions-item label="订单状态">
                        <el-tag :type="getStatusTagType(currentOrder.status_format)">
                            {{ currentOrder.status_format }}
                        </el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item label="是否结算">
                        <el-tag v-if="currentOrder.is_settlement == 1" type="success" size="small">是</el-tag>
                        <el-tag v-else type="danger" size="small">否</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item label="创建时间">{{ currentOrder.created_at }}</el-descriptions-item>
                    <el-descriptions-item label="备注">{{ currentOrder.remarks }}</el-descriptions-item>
                </el-descriptions>
            </div>


            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="showModal = false">关闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, defineExpose, getCurrentInstance } from 'vue';
import { Refresh } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { timeFormat } from "@/tools/time"

const { proxy } = getCurrentInstance();
// 控制弹出框的显示与隐藏
const showModal = ref(false);

// 当前选中的订单数据
const currentOrder = ref(null);

// 打开弹出框并设置当前订单数据
const openModal = (order) => {
    showModal.value = true;
    handleMore(order.id, order.created_at)
};

// 根据订单状态获取标签类型
const getStatusTagType = (status) => {
    switch (status) {
        case '支付成功': return 'success'; // 支付成功
        case '支付失败': return 'danger'; // 支付失败
        default: return 'info';
    }
};


//手动检测
const syncStatus = (id, created_at) => {
    proxy.$api.syncStatus({ id, created_at: timeFormat(created_at, 'date') }).then(res => {
        if (res.status === 200) {
            ElMessage({ message: '订单状态已重新同步', type: 'success' })
            handleMore(id, created_at)
        }
    }).catch(() => {
        ElMessage.error('同步失败')
    })
}

//查询订单详情
let moreinfoshow = ref(false)
const skeleton = ref(false)
const handleMore = (id, created_at) => {
    moreinfoshow.value = true
    skeleton.value = true
    proxy.$api.details({ id, created_at: timeFormat(created_at, 'date') }).then(r => {
        if (r.status == 200) {
            currentOrder.value = r.data.data
            skeleton.value = false
        }
    }).catch(() => {
        ElMessage.error('数据查询失败,请稍后重试')
    })
}

// 暴露 openModal 方法，供父组件调用
defineExpose({ openModal });
</script>

<style lang="less" scoped>
.page-modal {
    text-align: center;
    padding: 20px;

    .statuscheck {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
}

.dialog-footer {
    text-align: center;
}
</style>